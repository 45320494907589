<template>
    <div class="editTask">
        <Loading v-if="loading"></Loading>
        <soltList :visible = "sortShow" :taskTypes = "task_types" @close="(val)=>{sortShow = val}" @soltList="soltFun"></soltList>
        <template v-if="!loading">
            <div class="clearfix">
                <span class="title csP" @click="$router.go(-1)"><a-icon type="left" style="color:#6a87e6;margin-right:10px"/>{{result.title}}</span>
                <a-button class="btn fR"  @click="submit">提交</a-button>
                <a-button class="btn border fR" style="margin-right:20px"  @click="sort">排序</a-button>
            </div>
            <div class="taskBox">
                <div class="taskNum">
                    <div>
                        <template>
                            <div class="numItem" v-for="(item , index) in task_types" :key="index" >
                                <div class="sTitle" >
                                    <span>{{num_zn[index]}}、</span>
                                    <span>{{item.title}}</span>
                                    <span style="margin-left:10px;border-bottom:none;font-size:13px">{{item.describe}}</span>
                                </div>
                                <!-- 按照试卷排序 -->
                                <ul class="NumGroup clearfix" v-if="result.sort_type == 1">
                                    <li :class="{active: index == currentTask.parentIndex && currentTask.currentIndex == indexS, overEdit:item.data[indexS]}" @click="changeTask(item.type, indexS, index)" v-for="( num, indexS ) in Number(item.num)" :key="indexS">{{index == 0 ? num : Number(task_types[index-1].TotalParent)+num}}</li>
                                </ul>
                                <!-- 按照大题排序 -->
                                <ul class="NumGroup clearfix" v-else>
                                    <li :class="{active: index == currentTask.parentIndex && currentTask.currentIndex == indexS, overEdit:item.data[indexS]}" @click="changeTask(item.type, indexS, index)" v-for="( num, indexS ) in Number(item.num)" :key="indexS">{{num}}</li>
                                </ul>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="space"></div>
                <div class="right">
                    <!-- 材料题 -->
                    <div class="topicBox" style="margin-bottom:15px" v-if="currentTask.currentType =='material'">
                        <div class="title mb20x" >【材料题】</div>
                        <a-input type="text" placeholder="题干标题" v-model="stemTitle"/>
                        <a-textarea placeholder="请输入题干" :autoSize="{ minRows: 6 }" style="background:#eee;margin-top:20px;padding:10px" v-model="stem"/>
                    </div>
                    <!-- 单选题 -->
                    <div class="topicBox" v-if="currentTask.currentType =='single_choice' || ( currentTask.currentType =='material' && getMaterialType()[currentTask.currentIndex] == 'single_choice' )">
                        <div class="title"><span>第{{currentTask.currentIndex+1}}题【单选题】</span> </div>
                        <div class="bottomLine inputWrap">
                            <a-input placeholder="请输入题目" class="noBorder" v-model="defaultVal.single_choice.title"/>
                        </div>
                        <div class="bottomLine optionsItem">
                            <div class="title dpFlex"><span>请编辑题目选项</span> <span class="csP cBlue" @click="copyData" v-if="JSON.stringify(savedChoices) != '{}'">应用选项</span></div>
                            <a-radio-group v-model="defaultVal.single_choice.consult" class="w100pct">
                                <ul>
                                    <li v-for="(item, index) in defaultChoices" :key = "index">
                                        <a-radio :value="item"><span>{{item}}:</span></a-radio>
                                        <a-input placeholder="请输入" class="noBorder" v-model="defaultVal.single_choice[item]"/>
                                        <div v-if="index == defaultChoices.length - 1 && index > 2"><a-icon type="close" class="csP" @click="removeChoice('single_choice')"/></div>
                                    </li>
                                    <li>
                                        <a-button class="btn border" style="height:35px;width:100%" @click="addchoice('single_choice')"><a-icon type="plus"/>添加</a-button>
                                    </li>
                                </ul>
                            </a-radio-group>
                        </div>
                        <div class="answer">
                            <div class="title">参考答案：</div>
                            <div class="import">{{defaultVal.single_choice.consult}}</div>
                            <div class="title">解析：</div>

                            <div class="import">
                                <a-textarea placeholder="请输入解析" :autoSize="{ minRows: 6 }" style="background:#eee;margin-top:20px;padding:10px" v-model="defaultVal.single_choice.analysis"/>
                            </div>
                            <div class="title">考点：</div>
                            <div class="import"><a-input placeholder="请输入考点" class="noBorder" v-model="defaultVal.single_choice.point"/></div>
                        </div>
                    </div>
                     <!-- 多选题 -->
                    <div class="topicBox" v-if="currentTask.currentType =='multiple_choice' || ( currentTask.currentType =='material' && getMaterialType()[currentTask.currentIndex] == 'multiple_choice' )">
                        <div class="title">第{{currentTask.currentIndex+1}}题【多选题】</div>
                        <div class="bottomLine inputWrap">
                            <a-input placeholder="请输入题目" class="noBorder" v-model="defaultVal.multiple_choice.title"/>
                        </div>
                        <div class="bottomLine optionsItem">
                            <div class="title">请编辑题目选项</div>
                            <a-checkbox-group @change="onChange" style="width:100%" v-model="defaultVal.multiple_choice.consult">
                                <ul>
                                    <li v-for="(item, index) in defaultChoices" :key = "index">
                                        <a-checkbox :value="item" class="w58x"><span>{{item}}:</span></a-checkbox>
                                        <a-input placeholder="请输入" class="noBorder" v-model="defaultVal.multiple_choice[item]"/>
                                        <div v-if="index == defaultChoices.length - 1 && index > 2"><a-icon type="close" class="csP" @click="removeChoice('multiple_choice')"/></div>
                                    </li>
                                    <li>
                                        <a-button class="btn border" style="height:35px;width:100%" @click="addchoice('multiple_choice')"><a-icon type="plus"/>添加</a-button>
                                    </li>
                                </ul>
                            </a-checkbox-group>
                            <!-- <div class="more"><span>更多选项</span></div> -->
                        </div>
                        <div class="answer">
                            <div class="title">参考答案：</div>
                            <div class="import">
                                <span v-for="(item , index) in defaultVal.multiple_choice.consult" :key="index" class="mr10px">{{item}} <span v-if="index != defaultVal.multiple_choice.consult.length-1">,</span></span>
                            </div>
                            <div class="title">解析：</div>
                            <div class="import">
                                  <a-textarea placeholder="请输入解析" :autoSize="{ minRows: 6 }" style="background:#eee;margin-top:20px;padding:10px" v-model="defaultVal.multiple_choice.analysis"/>
                                <!-- <a-input placeholder="请输入解析" class="noBorder"/> -->
                            </div>
                            <div class="title">考点：</div>
                            <div class="import"><a-input placeholder="请输入考点" class="noBorder" v-model="defaultVal.multiple_choice.point"/></div>
                        </div>
                    </div>
                    <!-- 判断题 -->
                    <div class="topicBox" v-if="currentTask.currentType =='jud_question' || ( currentTask.currentType =='material' && getMaterialType()[currentTask.currentIndex] == 'jud_question' )">
                        <div class="title">第{{currentTask.currentIndex+1}}题【判断题】</div>
                        <div class="bottomLine inputWrap">
                            <a-input placeholder="请输入题目" class="noBorder" v-model="defaultVal.jud_question.title"/>
                        </div>
                        <div class="bottomLine optionsItem">
                            <div class="title">请编辑题目选项</div>
                            <a-radio-group v-model="defaultVal.jud_question.consult">
                                <ul>
                                    <li>
                                        <a-radio :value="'1'"><span>对</span></a-radio>
                                    </li>
                                    <li>
                                        <a-radio :value="'2'"><span>错</span></a-radio>
                                    </li>
                                </ul>
                            </a-radio-group>
                            <div class="title">请编辑原因（参考答案）：</div>
                            <a-textarea placeholder="原因" :autoSize="{ minRows: 3, maxRows: 6 }" v-model="defaultVal.jud_question.consultCont"/>
                            <div style="text-align:right;color:#999999;font-size:14px">{{defaultVal.jud_question.consultCont.length}}/300</div>
                        </div>
                        <div class="answer">
                            <div class="title">参考答案：</div>
                            <div class="import"><span v-if="defaultVal.jud_question.consult== 1">对</span><span v-if="defaultVal.jud_question.consult== 2">错</span><span v-if="defaultVal.jud_question.consultCont">，</span>{{defaultVal.jud_question.consultCont}}</div>
                            <div class="title">解析：</div>
                            <div class="import">
                               <a-textarea placeholder="请输入解析" :autoSize="{ minRows: 6 }" style="background:#eee;margin-top:20px;padding:10px" v-model="defaultVal.jud_question.analysis"/>
                            </div>
                            <div class="title">考点：</div>
                            <div class="import"><a-input placeholder="无" class="noBorder" v-model="defaultVal.jud_question.point"/></div>
                        </div>
                    </div>
                    <!-- 填空题 -->
                    <div class="topicBox" v-if="currentTask.currentType =='fill' || ( currentTask.currentType =='material' && getMaterialType()[currentTask.currentIndex] == 'fill' )">
                        <div class="title">第{{currentTask.currentIndex+1}}题【填空题】</div>
                        <div class="bottomLine inputWrap">
                            <a-input placeholder="请输入题目" class="noBorder" v-model="defaultVal.fill.title"/>
                        </div>
                        <div class="answer">
                            <div class="title">参考答案：</div>
                            <div class="import"><a-input placeholder="请输入参考答案" class="noBorder" v-model="defaultVal.fill.consult"/></div>
                            <div class="title">解析：</div>
                            <div class="import"><a-textarea placeholder="请输入解析" :autoSize="{ minRows: 6 }" style="background:#eee;margin-top:20px;padding:10px" v-model="defaultVal.fill.analysis"/></div>
                            <div class="title">考点：</div>
                            <div class="import"><a-input placeholder="请输入考点" class="noBorder" v-model="defaultVal.fill.point"/></div>
                        </div>
                    </div>
                    <!-- 论述题 -->
                    <div class="topicBox" v-if="currentTask.currentType =='answer' || ( currentTask.currentType =='material' && getMaterialType()[currentTask.currentIndex] == 'answer' )">
                        <div class="title">第{{currentTask.currentIndex+1}}题【简答题】</div>
                        <div class="bottomLine inputWrap">
                            <a-input placeholder="请输入题目" class="noBorder" v-model="defaultVal.answer.title"/>
                        </div>
                        <div class="answer">
                            <div class="title">参考答案：</div>
                            <div class="import"><a-input placeholder="请输入参考答案" class="noBorder" v-model="defaultVal.answer.consult"/></div>
                            <div class="title">文字解析：</div>
                            <div class="import"><a-textarea placeholder="请输入解析" :autoSize="{ minRows: 6 }" style="background:#eee;margin-top:20px;padding:10px" v-model="defaultVal.answer.analysis"/></div>
                            <div class="title">视频解析：</div>
                            <div class="import">
                                <a-upload
                                        name="file"
                                        :customRequest="customRequest"
                                        class="noBorder"
                                >
                                    <a-button>上传视频</a-button>
                                </a-upload>
                                <a-input placeholder="视频解析地址" class="noBorder" v-model="defaultVal.answer.video"/>
                            </div>
                            <div class="title">考点：</div>
                            <div class="import"><a-input placeholder="无" class="noBorder" v-model="defaultVal.answer.point"/></div>
                        </div>
                    </div>
                    <div style="padding:20px 0;text-align:center">
                        <a-button class="btn mr20px" @click="saveItem" ><a-icon type="save"/>保存该题</a-button>
                        <a-button class="btn border" @click="nextItem" v-if="!(currentTask.parentIndex == task_types.length-1 && currentTask.currentIndex == task_types[currentTask.parentIndex].num - 1)"><a-icon type="plus"/>下一题</a-button>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
import { uploadImg } from '@/libs/api'
import { getPaperDetail, addPracticePaperQuestions, getPracticePaperQuestions } from '@/libs/practice'
import Loading from '@/components/loading'
import soltList from '@/components/soltList'
import { verify } from '@/mixins/verify'
import { num_zn, taskType } from '@/libs/transform'
const defaultAllChoice = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'G', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'I', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
export default {
    name: 'editTask',
    components: { Loading, soltList },
    mixins: [ verify ],
    computed: {
        dragOptions() {
            return {
                animation: 0,
                group: "description",
                disabled: false,
                ghostClass: "ghost"
            };
        },
    },
    watch:{
        'defaultVal.jud_question.consultCont'(val){
            if(val.length>300){
                this.defaultVal.jud_question.consultCont = this.defaultVal.jud_question.consultCont.slice(0,300)
            }
        }
    },
    data(){
        return{
            num_zn,
            value:1,
            currentTask:{
                currentType:'single_choice',
                currentIndex:0,
                parentIndex:0
            },
            currentSort:0,
            id:'',
            sortShow: false,
            loading: true,
            result:{},
            arrayNumber:[],
            task_types:[],
            defaultChoices: [...defaultAllChoice].splice(0, 4),
            defaultVal:{
                single_choice:{
                    title:'',
                    A:'',
                    B:'',
                    C:'',
                    D:'',
                    consult:'A',
                    video: '',
                    analysis:'',
                    point:''
                },
                multiple_choice:{
                    title:'',
                    A:'',
                    B:'',
                    C:'',
                    D:'',
                    consult:[],
                    video: '',
                    analysis:'',
                    point:''
                },
                fill:{
                    title:'',
                    consult:'',
                    video: '',
                    analysis:'',
                    point:''
                },
                jud_question:{
                    title:'',
                    consult: '1',
                    video: '',
                    consultCont:'',
                    analysis:'',
                    point:''
                },
                answer:{
                    title:'',
                    consult: '',
                    video: '',
                    analysis:'',
                    point:''
                },
                material:{
                    title:'',
                    consult: '',
                    video: '',
                    analysis:'',
                    point:''
                }
            },
            stem:'',
            stemTitle: '',
            total:0,
            savedChoices:{}
        }
    },
    filters:{
    },
    created(){
        this.id = this.$route.query.id
    },
    mounted(){
        this.GetData()
    },
    methods:{
        taskType,
        getMaterialType(index){
            if(index == 0 || index){ // 材料题不是最后一大题的问题
                let currentType = this.task_types[index].type
                if(currentType == 'material'){
                    let types = []
                    this.task_types[index].materialTask.forEach((el,index)=>{
                        if(el){
                            types.push(...new Array(Number(el.num)).fill(el.type))
                        }
                    })
                    return types
                }
            }else{
                if(this.currentTask.currentType == 'material'){
                    let types = []
                    if(this.task_types.length){
                        this.task_types[this.currentTask.parentIndex].materialTask.forEach((el,index)=>{
                            if(el){
                                types.push(...new Array(Number(el.num)).fill(el.type))
                            }
                        })
                        return types
                    }else{
                        return []
                    }
                }
            }
        },
        sortMaterial(val){
            let marterArr = val.filter(el=>el.type == 'material')
            if(marterArr.length){
                marterArr.forEach((item,index)=>{
                    // types重组去重
                    if(item.data.length){
                        let arrs =  []
                        this._.uniq(item.data.map(dd=>dd.type)).forEach((el,indexs)=>{
                            arrs.push(item.materialTask.filter( element=>element.type == el)[0])
                        })

                        item.materialTask = arrs
                    }
                })
            }
        },
        customRequest(data){
            this.$message.info('上传完成');
            let formData = new FormData()
            formData.append('file',data.file)
            uploadImg(formData).then(res=>{
                if(res){
                    this.$message.info('上传完成');
                    this.defaultVal.answer.video = res.fileUrl;
                }
            },error=>{
                console.log(error)
            })
        },
        GetQuestions(taskTypes,solt){
            getPracticePaperQuestions({task_id:this.id}).then(res=>{
                if(res){
                    let { content } = res
                    let result = JSON.parse(content)
                    console.log(result)
                    result.forEach(el => {
                        el.data.forEach((item, index) => {
                            if(!el.data[index].id){
                                el.data[index].id = this.createRandomId()
                            }
                        })
                    })
                    if(result.length){
                        for(let index in taskTypes){
                            taskTypes[index].data = result[index].data
                            taskTypes[index].stem = result[index].stem
                            taskTypes[index].stemTitle = result[index].stemTitle
                        }
                        this.sortMaterial(taskTypes)
                        this.currentTask ={
                            currentType:taskTypes[0].type,
                            currentIndex:0,
                            parentIndex:0
                        }
                        if(taskTypes[this.currentTask.parentIndex].data[this.currentTask.currentIndex]){
                             let type = null
                            if(this.currentTask.currentType == 'material'){

                                taskTypes[0].materialTask.forEach((el,index)=>{
                                    if(el){
                                       type = el.type
                                    }
                                })
                                this.stem = taskTypes[0].stem
                                this.stem = taskTypes[0].stemTitle
                                this.defaultVal[type] = JSON.parse(JSON.stringify(taskTypes[this.currentTask.parentIndex].data[this.currentTask.currentIndex]))
                            }else{
                                type = this.currentTask.currentType

                            }
                            this.defaultVal[type] = JSON.parse(JSON.stringify(taskTypes[this.currentTask.parentIndex].data[this.currentTask.currentIndex]))
                            this.resetDefaultChoices(type)
                        }

                    }
                }
                this.task_types = JSON.parse(JSON.stringify(taskTypes))

            })
        },
        dealArr(){
            let arr = []
            this.task_types.forEach((el, index)=>{
                for(let i = 0; i< Number(el.num);i++){
                    arr.push({
                        parentIndex: index,
                        sIndex: i
                    })
                }
            })
            return arr
        },
        createRandomId() {
            return Math.random().toString(36).substr(3,10);
        },
        GetData(solt=false){
            this.loading=true
            getPaperDetail({ id:this.id }).then(res=>{
                if(res){
                    this.result = res
                    let { task_types } = res
                    let taskTypes = JSON.parse(task_types)
                    taskTypes.forEach((el,index)=>{
                        if(index>0){
                            el.TotalParent = Number(taskTypes[index-1].TotalParent) + Number(el.num)
                        }else{
                            el.TotalParent = Number(el.num)
                        }
                        el.data=[]
                        if(el.type=="material"){
                            let total = 0
                            el.materialTask.forEach(item=>{
                                total += Number(item.num)
                            })
                            el.num = total
                        }
                    })
                    this.currentSort = 0
                    this.currentTask.currentType = taskTypes[0].type
                    this.GetQuestions(taskTypes, solt)
                }
                this.loading=false;
            },error=>{
                console.log(error)
                this.loading=false
            })
        },
        changeTask( name, index, parentIndex ){
            if(!this.task_types[parentIndex].data.length) return
            this.resetData()
            this.currentTask.currentType = name
            this.currentTask.parentIndex = parentIndex
            if(this.task_types[parentIndex].data.length){
                if(index < this.task_types[parentIndex].data.length){
                    //已经编辑过的可以切换
                    this.currentTask.currentIndex = index

                }else{
                    this.currentTask.currentIndex = this.task_types[parentIndex].data.length-1
                }
                if(this.result.sort_type == 1){
                    this.currentSort = this.dealArr().findIndex(el=>el.parentIndex == parentIndex && el.sIndex ==  this.currentTask.currentIndex)
                }
                //将保存的数据赋值给默认值
                if( this.currentTask.currentType == "material" ){
                    let type = this.getMaterialType()[this.currentTask.currentIndex]
                    this.stem =JSON.parse(JSON.stringify(this.task_types[parentIndex].stem))
                    this.stemTitle = this.task_types[parentIndex].stemTitle? JSON.parse(JSON.stringify(this.task_types[parentIndex].stemTitle)) :''
                    this.defaultVal[type] =JSON.parse(JSON.stringify(this.task_types[parentIndex].data[this.currentTask.currentIndex]))
                    if(type == 'single_choice' || type =="multiple_choice"){
                        this.resetDefaultChoices(type)
                    }

                }else{
                    this.defaultVal[name] =JSON.parse(JSON.stringify(this.task_types[parentIndex].data[this.currentTask.currentIndex]))
                    this.resetDefaultChoices(this.currentTask.currentType)
                }
            }
        },
        getNextItem(){
            let { currentType, currentIndex, parentIndex } = this.currentTask
            if( currentIndex + 1 < this.task_types[parentIndex].num){
                return { type:this.task_types[parentIndex].type, num:this.task_types[parentIndex].num , index: currentIndex+1 ,parentIndex}
            }else{
                return { type:this.task_types[parentIndex+1].type, num:this.task_types[parentIndex+1].num ,index:0 ,parentIndex:parentIndex+1 }
            }
        },
        saveItem(){
            if(this.verifyData()){
                let {currentIndex, parentIndex, currentType} = this.currentTask
                let type = null
                if(this.task_types[parentIndex].type == 'material'){
                   this.task_types[parentIndex].stem = JSON.parse(JSON.stringify(this.stem))
                   this.task_types[parentIndex].stemTitle = JSON.parse(JSON.stringify(this.stemTitle))
                }
                if(currentType == 'material'){
                    type = this.getMaterialType(parentIndex)[currentIndex]
                }else{
                    type = currentType
                }
                if(this.task_types[parentIndex].data[currentIndex]){
                    this.task_types[parentIndex].data[currentIndex] = {...this.defaultVal[type]}
                }else{
                    this.task_types[parentIndex].data.push({...this.defaultVal[type], type})
                }
                this.$message.success('保存成功')
            }
            this.savedChoices = {...this.defaultVal['single_choice']}
        },
        nextItem(){
            if(this.verifyData()){
                let { currentType, currentIndex, parentIndex } = this.currentTask
                if(this.task_types[parentIndex].type == 'material'){
                   this.task_types[parentIndex].stem = JSON.parse(JSON.stringify(this.stem))
                   this.task_types[parentIndex].stemTitle = JSON.parse(JSON.stringify(this.stemTitle))
                }
                // 首先判断是否是最后一个
                if(this.result.sort_type == 1){
                    this.currentSort = this.currentSort + 1
                }
                let next = this.getNextItem()
                this.currentTask.currentType = next.type
                this.currentTask.currentIndex = next.index
                this.currentTask.parentIndex = next.parentIndex
                this.savedChoices = {...this.defaultVal['single_choice']}
                if(!this.task_types[next.parentIndex].data[next.index]){
                    this.defaultChoices = [...defaultAllChoice].splice(0, 4)
                    //下一个没有数据并且这条未添加过需要添加内容
                    if(!this.task_types[parentIndex].data[currentIndex]){
                        if(currentType == 'material' ){
                            if(next.index !=0){
                                let type = this.getMaterialType()[currentIndex]
                                this.task_types[next.parentIndex].stem = this.stem
                                this.task_types[next.parentIndex].stemTitle = this.stemTitle
                                this.task_types[next.parentIndex].data.push({...this.defaultVal[type],type})
                            }else{
                                let index = this.task_types[parentIndex].num -1
                                let type = this.getMaterialType(parentIndex)[index]
                                this.task_types[next.parentIndex-1].data.push({...this.defaultVal[type],type})
                            }
                        }else{
                            this.task_types[parentIndex].data.push({...this.defaultVal[currentType],type: currentType})
                        }
                    }else{
                        console.log(next.index)
                    }
                    this.resetData()
                }else{
                    //有数据的情况下，替换值
                    let type = this.getMaterialType(parentIndex)[currentIndex]
                    this.task_types[parentIndex].data[currentIndex] = {...this.defaultVal[type]}
                    //赋值题干
                    if(next.type == 'material'){
                        let type = this.getMaterialType()[next.index]
                        this.stem = JSON.parse(JSON.stringify(this.task_types[next.parentIndex].stem))
                        this.stemTitle = JSON.parse(JSON.stringify(this.task_types[next.parentIndex].stemTitle))
                        this.defaultVal[type] = JSON.parse(JSON.stringify(this.task_types[next.parentIndex].data[next.index]))
                    }else{
                        this.defaultVal[next.type] = JSON.parse(JSON.stringify(this.task_types[next.parentIndex].data[next.index]))
                    }
                }
            }
        },
        onChange(checkedValues){
           this.defaultVal.multiple_choice.consult = checkedValues
        },
        sort(){
            let defaultNum = 0;let importNum = 0
            this.task_types.forEach(el=>{
                defaultNum += Number(el.num)
                importNum += Number(el.data.length)
            })
            if( defaultNum == importNum ){
                this.sortShow = true
            }else{
                this.$message.error('请先编辑试卷！')
                return
            }
        },
        soltFun(val){
            this.task_types = this._.cloneDeep(val)
            this.submit(true)
        },
        submit(isSolt = false){
            let request = this.task_types.map(el=>({
                data:el.data,
                type:el.type,
                stem:el.stem?el.stem:null,
                stemTitle:el.stemTitle?el.stemTitle:null
            }))
            let defaultNum = 0;let importNum = 0
            this.task_types.forEach(el=>{
                defaultNum += Number(el.num)
                importNum += Number(el.data.length)
            })
            console.log(request)
            addPracticePaperQuestions({content:JSON.stringify(request), task_id:this.id, progress: Math.floor(importNum/defaultNum*100)}).then(res=>{
                if(res.length==0){
                    if(isSolt){
                        this.$message.success('修改成功')
                        this.GetData(true)
                    }else{
                        this.$message.success('提交成功')
                    }
                }
            },error=>{
                console.log(error)
            })
        },

        addchoice(type){
            let length = this.defaultChoices.length
            let value = [...defaultAllChoice].splice(length, 1)[0]
            this.defaultChoices.push(value)
            this.defaultVal[type][value] = ''
        },
        removeChoice(type){
            let length = this.defaultChoices.length
            let value = this.defaultChoices[this.defaultChoices.length-1]
            this.defaultChoices = [...this.defaultChoices].splice(0, length-1)
            delete this.defaultVal[type][value]
        },
        copyData(){
            this.savedChoices.title = ''
            this.defaultVal['single_choice'] = {...this.savedChoices}
           this.resetDefaultChoices('single_choice')
        },
        resetDefaultChoices(type){
            if(type == 'single_choice' || type =="multiple_choice"){
                this.defaultChoices = []
                for(let key in this.defaultVal[type]){
                    if(defaultAllChoice.findIndex(el => el == key) > -1){
                        this.defaultChoices.push(key)
                    }
                }
                this.defaultChoices.sort()
            }
        }
    }
}
</script>
<style lang="less" scoped>
.editTask{
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 30px 50px;
    overflow: hidden;
    .title{
        font-size: 20px
    }
    .btn{
        width: 120px
    }
    .taskBox{
        width: 100%;
        flex: 1;
        margin-top: 30px;
        display: inline-flex;
        font-size: 16px;
        overflow: hidden;
        .taskNum{
            width: 358px;
            background: #fff;
            border-radius: 4px;
            overflow: hidden;
            padding:20px 0;
            >div{
                overflow: auto;
                padding: 0 20px;
                height: 100%;
            }
            .numItem{
                .sTitle{
                    border-bottom: 1px solid #eee;
                    font-size: 16px;
                    color: #666;
                    line-height: 2.5;
                    >span{
                        display: inline-block;
                        border-bottom: 1px solid #4165e0
                    }

                }
                .NumGroup{
                    margin-top: 10px;
                    li{
                        float: left;
                        font-size: 16px;
                        margin-right: 5px;
                        width: 35px;
                        height: 35px;
                        border: 1px solid #d8d8d8;
                        border-radius: 4px;
                        line-height: 35px;
                        text-align: center;
                        margin-bottom: 5px;
                        cursor: pointer;
                        &:nth-child(8n){
                            margin-right: 0
                        }
                        &.active,&.active.overEdit{
                            border: none;
                            background: #4165e0;
                            color: #fff
                        }
                        &.overEdit{
                            border:1px solid #4165e0;
                            color: #4165e0
                        }

                    }
                }
            }
        }
        .space{
            width: 40px
        }
        .right{
            flex: 1;
            overflow: auto;
            .topicBox{
                background: #fff;
                border-radius: 4px;
                padding: 20px;
                .title{
                    line-height: 2.5;
                    font-size: 16px;
                    border-bottom: 1px solid #eeeeee;
                }
                .inputWrap{
                    padding: 10px 0;
                    .ant-input{
                        padding-left: 0;
                        font-size: 16px
                    }
                }
                .optionsItem{
                    padding: 15px 0;
                    .title{
                        font-size: 16px;
                        color: #999999;
                        border: none;

                    }
                    ul{
                        margin-bottom: 0;
                        li{
                            padding: 10px 0;
                            display: flex;
                            align-items: center;
                            >*{
                                margin-right: 10px
                            }
                            span{
                                color: #5180e0
                            }
                        }
                    }
                    .more{
                        text-align:center;
                        cursor: pointer;
                        color: #999;
                        padding: 5px 0 10px
                    }
                }
                .answer{
                    padding-top: 10px;
                    .title{
                        line-height: 30px;
                        color: #333;
                        border-bottom: none;
                        margin: 5px 0;
                    }
                    .import{
                        line-height: 30px;
                        color: #999;
                        .ant-input{
                            padding-left: 0
                        }
                    }
                }
            }
        }
    }
}
.bottomLine{
    border-bottom: 1px solid #eee;
}
.w58x{width: 58px}
</style>
